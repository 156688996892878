/* eslint-disable no-console */
import React, { useRef, useEffect, useMemo } from 'react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from '../../routes';
import { PageLayout } from 'tmna-ui-kit';
import { Content } from '@backstage/core-components';
import {
  Grid,
  Button,
  Tooltip,
  Popover,
  CircularProgress,
  Card,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import { Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useLocation } from 'react-router-dom';
import { TimeRanges } from '../../shared/TimeRanges';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker } from '@material-ui/pickers';
import { addMonths } from 'date-fns';
import Popper from '@material-ui/core/Popper';
import { useStyles } from './styles';
import { useAsyncFn, useAsync } from 'react-use';
// eslint-disable-next-line @backstage/no-forbidden-package-imports
import { costCenterApiRef } from '@internal/plugin-cost-center/src/api';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import dayjs from 'dayjs';
import { _values } from 'tmna-utils';
import { ChargebacksDetailTable } from '../../shared/ChargebacksDetailTable';
import { mapChargebacksToTableData } from '../../shared/ChargebacksDetailTable/utils';
import * as XLSX from 'xlsx';
import { SpendRateGraph } from '../../shared/SpendRateGraph/SpendRateGraph';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router';
import DetailsIcon from '@material-ui/icons/Details';
import { ChargebacksPayload } from 'tmna-types';
import {
  SupportButton,
  SupportTextList,
  SupportLinks,
  SupportMultipleLinkCard,
} from 'tmna-ui-kit';
import VideocamIcon from '@material-ui/icons/Videocam';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

const defaultEndDate = addMonths(new Date(), -1).toUTCString();
const minFrom2020April = new Date(2020, 3, 1);
const maxToMonth = new Date();


const FAQ_DOC =
  [
    {
      to: '/docs/default/Component/ACE-FAQ/access-and-onboarding/cloud-account-master/',
      title:'CAM FAQ',
    },
    {
      to: '/docs/default/Component/ACE-FAQ/Compliance/cloud-admin/',
      title: 'Break Glass FAQ'
    }
  ]

const RELATED_DOCS_SUPPORT_LINKS = [
  {
    to:
      'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-1.mp4?csf=1&web=1&e=MTbwV1',
    title: 'How do I view Cloud Accounts and Details?',
  },
  {
    to:
      'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-2.mp4?csf=1&web=1&e=geu2Nk',
    title: 'How do I view Chargebacks for a given Account?',
  },
  {
    to:
      'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-3.mp4?csf=1&web=1&e=CD7zHb',
    title: ' How do I look up Spend Rate Graph?  ',
  },
  {
    to:
      'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-4.mp4?csf=1&web=1&e=7tjcsj',
    title: 'How do I download Cloud Account Information?',
  },
  {
    to:
      'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-5.mp4?csf=1&web=1&e=CxjSms',
    title: 'How do I download Chargeback details?',
  },
  {
    to:
      'https://myteams.toyota.com/:v:/r/sites/CHOFER-CloudDeveloperExperience/Shared%20Documents/Chofer-Portal/CAM%20videos/CAM-6.mp4?csf=1&web=1&e=bl6sbx',
    title: 'What bulk operations can users perform?',
  },
  {
    to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/CAM-7.mp4?csf=1&web=1&e=2DeQuK',
    title:'How do I edit an account?',
  },
  {
    to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/CAM-8.mp4?csf=1&web=1&e=bOcPuQ',
    title:'How do I delete an account?',
  },
  {
    to: 'https://myteams.toyota.com/:v:/r/sites/NA-108/Chofer%20Videos/CAM-9.mp4?csf=1&web=1&e=ogoXqq',
    title:'How do I create an account?',
  }
];
export type CloudChargeFormValues = {
  accountIds: string[];
  startDate: string;
  endDate: string;
};
export type ChargebackFormValues = {
  accountIds: string[];
  startDate: string;
  endDate: string;
};

type ChargeBackPayloadRes = Omit<ChargebacksPayload, 'months'> & {
  months: string[];
  dataCommon: { [key: string]: number };
};

type GetCBDetail = (
  accIds: string[],
  params: Omit<ChargebackFormValues, 'accountIds'>,
) => Promise<ChargeBackPayloadRes>;

const startOfFiscalYear = () => {
  const date = new Date(defaultEndDate);
  const fiscalYearStart = new Date(date.getFullYear(), 3, 1);
  if (date.getMonth() < 3) {
    fiscalYearStart.setFullYear(fiscalYearStart.getFullYear() - 1);
  }
  return new Date(fiscalYearStart).toUTCString();
};

const getStartDate = (routerValues: any) => {
  const chargeBackHome = routerValues?.type === 'chargeBackHome';
  const fromHomePage = routerValues?.fromHomePage;
  switch (true) {
    case chargeBackHome && fromHomePage: {
      return startOfFiscalYear();
    }
    default: {
      return addMonths(new Date(), -9).toUTCString();
    }
  }
};

export const CloudChargeBackDetails = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [resultData, setResultData] = React.useState([] as any);
  const [dateShow, setDateShow] = React.useState([] as any);


  const location: any = useLocation();
  const { state: routerState } = location;
  const [routerValues, setRouterValues] = React.useState(routerState as any);
  const [defaultStartDate] = React.useState(getStartDate(routerValues));

  const [accNameLoader, setAccNameLoader] = React.useState(false);
  const isSpendrate: boolean = routerValues?.type === "SpendRateButton" ? true : false;
  const [showSpendRate, setShowSpendRate] = React.useState(isSpendrate as any);
  const costCenterApi = useApi(costCenterApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const selectedAccounts: string[] = useMemo(
    () =>
      routerValues?.accountIds.map(
        ({ account_number }: any) => account_number,
      ) ?? [],
    [routerValues?.accountIds],
  );
  const LightTooltip = withStyles(theme => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
      marginTop: '-5px',
    },
  }))(Tooltip);

  useEffect(() => {
    const getAccIdDetails = async () => {
      setAccNameLoader(true);
      const token: string = await authref.getIdToken();
      const accountIdsData = routerState?.accountIds ?? [];
      const accountIds = accountIdsData.map(
        ({ account_number }: any) => account_number,
      );
      const businessUnits = await costCenterApi.fetchCamAccountIds({}, token);
      const accountIdsRes = businessUnits?.filter(({ account_number }) =>
        accountIds.includes(account_number),
      );

      setRouterValues((prevState: any) => ({
        ...prevState,
        accountIds: accountIdsRes,
      }));
      setAccNameLoader(false);
    };
    if (routerValues?.fromHomePage) {
      getAccIdDetails();
    } else {
      setRouterValues(routerState);
    }
  }, [authref, costCenterApi, routerState, routerValues?.fromHomePage]);


  const navigate = useNavigate();

  const redirectToCamDetails = () => {
    selectedAccounts.length == 1 &&
      navigate(
        `/cloud-account/CloudAccountDetails?accountId=${selectedAccounts}`,
      );
  };

  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);
  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Cloud Account Master',
    }),
    [rootLink],
  );
  const { handleSubmit, control, watch } = useForm<CloudChargeFormValues>({
    mode: 'onChange',
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  });

  const { startDate, endDate } = watch();

  const ref = useRef<HTMLDivElement>(null);

  const splitArray = <T,>(arr: T[], size: number): T[][] =>
    arr.length > size
      ? [arr.slice(0, size), ...splitArray(arr.slice(size), size)]
      : [arr];

  const getCBDetail: GetCBDetail = async (accIds, params) => {
    const splicedAccIds = splitArray(accIds, 200);
    const promiseReq = splicedAccIds.map(accountIds =>
      costCenterApi.fetchChargebacksDetailByAccountIds({
        ...params,
        accountIds,
      }),
    );

    const promiseRes = (await Promise.all(
      promiseReq,
    )) as ChargeBackPayloadRes[];

    const reducedData = promiseRes.reduce(
      (a: ChargeBackPayloadRes, b: ChargeBackPayloadRes) => {
        const dataCommonArray = Object.entries(
          b.dataCommon,
        ).map(([key, value]) => [
          key,
          isNaN(value) ? value : (a.dataCommon[key] ?? 0) + value,
        ]);
        const dataCommon = Object.fromEntries(dataCommonArray);
        const accountsData = [...a.accountsData, ...b.accountsData];
        const months = Array.from(new Set([...a.months, ...b.months]));
        return { dataCommon, accountsData, months };
      },
      { accountsData: [], dataCommon: {}, months: [] },
    );
    return reducedData;
  };

  const [{ value: _data, loading }, onSubmitChargeback] = useAsyncFn(
    async (params: ChargebackFormValues) => {
      
      const paramsData = {
        startDate: dayjs(params.startDate).format('YYYY-MM'),
        endDate: dayjs(params.endDate).format('YYYY-MM'),
      };

      const responseData = await getCBDetail(selectedAccounts, paramsData);

      const customDate1 = `${dayjs(params.startDate).format(
        'MMM YYYY',
      )} - ${dayjs(params.endDate).format('MMM YYYY')}`;
      setDateShow(customDate1.toUpperCase());
      if (responseData !== null) {
        setResultData(responseData);
      } else {
        setResultData({});
      }
      return responseData;
    },
    [],
  );

  const {
    value: _defaultLoadData = [] as any,
    loading: defaultLoadDataLoading,
  } = useAsync(async () => {

    const params = {
      startDate: dayjs(defaultStartDate).format('YYYY-MM'),
      endDate: dayjs(defaultEndDate).format('YYYY-MM'),
    };

    const defaultData = await getCBDetail(selectedAccounts, params);

    const customDate2 = `${dayjs(defaultStartDate).format(
      'MMM YYYY',
    )} - ${dayjs(defaultEndDate).format('MMM YYYY')}`;

    setDateShow(customDate2.toUpperCase());
    if (defaultData !== null) {
      setResultData(defaultData);
    } else {
      setResultData({});
    }

    return defaultData;
  }, []);

  const tableData = React.useMemo(
    () =>
      resultData?.accountsData &&
      mapChargebacksToTableData(resultData?.accountsData),
    [resultData],
  );

  React.useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setAnchorEl(null);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  const open = Boolean(anchorEl);

  const handleClickExport = (event: any) => {
    setIsMenuOpen(oldState => !oldState);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };



  const displayAccountIds = () => {
    return routerValues?.accountIds.length < 6
      ? routerValues?.accountIds.map((item: any, index: any) => {
        return (
          <span
            key={index}
            style={{
              fontWeight: 'initial',
              fontSize: '14px',
              display: 'block',
              width: '224px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {item.account_number} <br />
          </span>
        );
      })
      : popOver(routerValues?.accountIds);
  };
  const displayAccountNames = () => {
if (accNameLoader)
  return (
    <div className={classes.accNameLoader}>
      <CircularProgress />
    </div>
  );
    return routerValues?.accountIds.length < 6
      ? routerValues?.accountIds.map((item: any, index: any) => {
        return (
          <span
            key={index}
            style={{
              fontWeight: 'initial',
              fontSize: '14px',
              display: 'block',
              width: '224px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {item.account_name} <br />
          </span>
        );
      })
      : popOver2(routerValues?.accountIds);
  };

  const [openedPopover, setOpenedPopover] = React.useState(false);
  const [openedPopover2, setOpenedPopover2] = React.useState(false);
  const popoverAnchor = useRef(null);
  const popoverAnchor2 = useRef(null);
  const popoverEnter = () => {
    setOpenedPopover(true);
  };
  const popoverEnter2 = () => {
    setOpenedPopover2(true);
  };
  const popoverLeave = () => {
    if (openedPopover) {
      setOpenedPopover(false);
    }
  };
  const popoverLeave2 = () => {
    if (openedPopover2) {
      setOpenedPopover2(false);
    }
  };
  const popOver = (selectedAccounts: any) => {
    const firstfourAccounts: Array<any> = selectedAccounts?.slice(0, 5);
    const allAccounts: string =
      selectedAccounts &&
      selectedAccounts.map((u: any) => u.account_number).join(', ');
    return (
      <>
        <div
          ref={popoverAnchor}
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          {firstfourAccounts?.map((item, index) => {
            return (
              <span
                key={index}
                style={{
                  fontWeight: 'initial',
                  fontSize: '14px',
                  display: 'block',
                  width: '224px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.account_number} {index === 4 ? '...' : ''} <br />
              </span>
            );
          })}
        </div>
        <Popover
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
        >
          <div
            style={{
              width: '500px',
              height: '200px',
              overflowY: 'auto',
              wordBreak: 'break-all',
              padding: '1%',
            }}
          >
            <span
              style={{ display: 'block', fontSize: '12px', fontWeight: 'bold' }}
            >
              Selected Account ID(s) ({selectedAccounts?.length}):
            </span>
            <span style={{ fontSize: '10px', display: 'block' }}>
              {allAccounts}
            </span>
          </div>
        </Popover>
      </>
    );
  };
  const popOver2 = (selectedAccounts: any) => {
    const firstfourAccounts: Array<any> = selectedAccounts?.slice(0, 5);
    const allAccounts: string =
      selectedAccounts &&
      selectedAccounts.map((u: any) => u.account_name).join(', ');
    return (
      <>
        <div
          ref={popoverAnchor2}
          onMouseEnter={popoverEnter2}
          onMouseLeave={popoverLeave2}
        >
          {firstfourAccounts?.map((item, index) => {
            return (
              <span
                key={index}
                style={{
                  fontWeight: 'initial',
                  fontSize: '14px',
                  display: 'block',
                  width: '224px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.account_name} {index === 4 ? '...' : ''} <br />
              </span>
            );
          })}
        </div>
        <Popover
          open={openedPopover2}
          anchorEl={popoverAnchor2.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            onMouseEnter: popoverEnter2,
            onMouseLeave: popoverLeave2,
          }}
        >
          <div
            style={{
              width: '500px',
              height: '200px',
              overflowY: 'auto',
              wordBreak: 'break-all',
              padding: '1%',
            }}
          >
            <span
              style={{ display: 'block', fontSize: '12px', fontWeight: 'bold' }}
            >
              Selected Account Names ({selectedAccounts?.length}):
            </span>
            <span style={{ fontSize: '10px', display: 'block' }}>
              {allAccounts}
            </span>
          </div>
        </Popover>
      </>
    );
  };
  const handleDownloadExcel = () => {
    if (tableData !== undefined) {
      const monthsSorted: any = resultData?.months.filter((item: string) => {
        return item !== 'total';
      }).sort((a: string, b: string) => {
        const fisrtMonth: any = new Date(b);
        const secondMonth: any = new Date(a)
        return fisrtMonth - secondMonth;
      });
      const newData = tableData && tableData.map((row: { accountId: any; period: any }) => {
        return { accountId: row.accountId, ...row.period };
      });
      let newArr: any[] = [];
      newData && newData.map((item: any) => {
        let newObj: any = {};
        for (const key in item) {
          if (key == 'accountId') {
            newObj['Account ID'] =
              item[key] === undefined ? '' : item[key];
          } else if (key == 'Account_Name') {
            newObj['Account Name'] = item[key] === undefined ? '' : item[key];
          } else if (key == 'estimated_budget') {
            newObj['Estimated Spend'] =
              item[key] === undefined ? '' : item[key];
          }
        }
        monthsSorted.map((value: any) => {
          newObj[value] = item[value] === undefined ? '' : item[value];
        })
        newObj['Total'] = item['total'] === undefined ? '' : item['total'];
        newArr.push(newObj);
      });
      if (newArr.length > 0) {
        const workSheet = XLSX.utils.json_to_sheet(newArr);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'CAM');
        XLSX.write(workBook, { bookType: 'csv', type: 'binary' });
        XLSX.writeFile(workBook, 'Chargeback_CAM.xlsx');
      }
    }
  };

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const id = open ? 'simple-popper1' : undefined;

  /* Click event of spend rate icon.*/
  const handleClickShowSpendRate = () => {
    setShowSpendRate(!showSpendRate);
  };

  /* Fecth SpendRate when show spendRate is true*/

  useEffect(() => {
    if (showSpendRate === true) {
      fecthSpendRateDetails();
    }

  }, [showSpendRate])


  /* Reading Accountids from router.*/



  /* Api call to fetch spend rate details based on selected accountId's.*/
  const [spendRateState, fecthSpendRateDetails] = useAsyncFn(async () => {
    const result = await costCenterApi.fetchCloudSpendGraphDetailsbyAccountId(
      selectedAccounts,
    );
    return result.accounts_total ? result : null;
  }, [JSON.stringify(selectedAccounts)]);

  /* UI to display failed to fetch for Spend Rate */
  const displaySpendRateFailedtoFetch = () => {
    return (
      <Card>
        <Box position="relative" data-testid="bar-chart-wrapper">
          <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>Failed to fetch the Graph Details</h1>
          </div>
        </Box>
      </Card>
    )
  }

  /* UI to display Loader for Spend Rate */
  const dispalySpendRateLoader = () => {
    return (
      <Card>
        <Box position="relative" data-testid="bar-chart-wrapper">
          <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress
              style={{
                color: 'red',
              }}
            />
          </div>
        </Box>
      </Card>
    )
  }

  /* UI to display Spend Rate */
  const renderSpendRate = () => {
    return (
      <div className={classes.tableContainer}>
        {spendRateState.loading ? (
          dispalySpendRateLoader()
        ) : !spendRateState.value ? (
          displaySpendRateFailedtoFetch()
        ) : (
          <SpendRateGraph responseData={spendRateState.value} />
        )}
      </div>
    );
  };
  const FunnelIcon = () => {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.00018 2.5H13.0002L7.99018 8.8L3.00018 2.5ZM0.250178 2.11C2.27018 4.7 6.00018 9.5 6.00018 9.5V15.5C6.00018 16.05 6.45018 16.5 7.00018 16.5H9.00018C9.55018 16.5 10.0002 16.05 10.0002 15.5V9.5C10.0002 9.5 13.7202 4.7 15.7402 2.11C16.2502 1.45 15.7802 0.5 14.9502 0.5H1.04018C0.210178 0.5 -0.259822 1.45 0.250178 2.11Z"
          fill="rgba(0, 0, 0, 0.26)"
        />
      </svg>
    );
  };
  const redirectToSetting = () => {
       
    navigate('/settings', {
      state: { tabId: "two" },
    });
};
  return (
    <PageLayout
      title={
        showSpendRate
          ? 'Spend Rate Graph for Cloud Account(s)'
          : 'Chargeback for Cloud Account(s)'
      }
      subtitle={
        showSpendRate
          ? 'View estimated budget for cloud account(s) for a given period and forecast cloud spend for 3 months based on cloud spend history.'
          : 'View estimated budget and actual spend for cloud account(s) for a given period.'
      }
      headerBackgroundImg={'/assets/service-catalog_bg.png'}
      backToLink={backToLink}
      displayButton={true}
    >
      <Paper>
        <Content>
          <Grid container spacing={3} style={{justifyContent: "space-between"}}>
            <Grid
              item
              xs={3}
              className={classes.tabHeading}
              data-testid="ChargeBackHeading"
            >
              <p style={{ marginLeft: '2px' }}>
                {showSpendRate ? 'Spend Rate Graph' : 'Chargeback'}
              </p>
            </Grid>
            <div style={{display:'flex',alignItems:'center'}}>
                    <div className={classes.parentSupport}><SupportButton tooltipTitle="Support" supportIcon="cloud">
                    <SupportTextList>
                      <Typography variant="body2">
                        Cloud Account Master (CAM) is the list of all cloud accounts in TMNA, where key information about
                        the account is actively managed throughout its lifecycle.
                      </Typography>
                    </SupportTextList>
                    <SupportLinks>
                      <SupportMultipleLinkCard
                        icon={<HelpIcon />}
                        title="Related Links"
                        items={FAQ_DOC}
                      />
                    </SupportLinks>
                    <SupportLinks>
                      <SupportMultipleLinkCard
                        icon={<VideocamIcon />}
                        title="Related Videos"
                        items={RELATED_DOCS_SUPPORT_LINKS}
                      />
                    </SupportLinks>
                  </SupportButton></div>
                  <LightTooltip title="CAM Details">
                    <IconButton className={classes.bulkIcon} aria-label="cost">
                      <DetailsIcon
                          aria-label="View"
                          data-testid="camDetails"
                          onClick={redirectToCamDetails}
                          style={{ cursor: 'pointer',color:selectedAccounts.length == 1 ? 'rgb(255, 0, 0)':'#9B9C9D' }}
                        />
                    </IconButton>
                  </LightTooltip>
                  {showSpendRate ? (
                <>
                <LightTooltip title="Chargeback">
                  <IconButton className={classes.bulkIcon} aria-label="cost" >
                    <MonetizationOnOutlinedIcon
                      data-testid="ChargeBackButton"
                      onClick={handleClickShowSpendRate}
                      style={{color:'rgb(255, 0, 0)'}}                      
                    />
                </IconButton>
                </LightTooltip>
                <LightTooltip title="Spend Rate Graph">
                    <IconButton className={classes.bulkIcon} aria-label="Spend Rate Graph" >
                      <BarChartIcon
                        data-testid="SpendRateButton"
                        color='disabled'
                      />
                    </IconButton>
                  </LightTooltip>
              </>
              ) : (
                <>
                 <LightTooltip title="Chargeback">
                  <IconButton className={classes.bulkIcon} aria-label="cost" >
                    <MonetizationOnOutlinedIcon
                      data-testid="ChargeBackButton"
                      color={'disabled'}
                      
                    />
                </IconButton>
                </LightTooltip>
                <LightTooltip title="Spend Rate Graph">
                    <IconButton className={classes.bulkIcon} aria-label="Spend Rate Graph" >
                      <BarChartIcon
                        data-testid="SpendRateButton"
                        onClick={handleClickShowSpendRate}
                        style={{color:'rgb(255, 0, 0)'}}
                      />
                    </IconButton>
                  </LightTooltip>
                </>
              )}
                  <LightTooltip title="View Real-Time Cost & Usage in Cloudability">
                    <IconButton className={classes.bulkIcon} aria-label="cost">
                      <a
                       href='https://app.apptio.com/cloudability'
                      target='_blank'
                      style={{ height: "23px",width: "23px"}}
                      >
                        <img
                        src='/assets/apptio.png'
                        style={{ width: "100%",height:'100%' }}
                      />
                      </a>
                    </IconButton>
                  </LightTooltip>
                
                  <Box className={classes.iconContainer}>
                      <Tooltip title="Download">
                        <GetAppIcon
                          aria-label="View"
                          data-testid="downloadButton"
                          onClick={handleClickExport}
                          style={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </Box>
                {isMenuOpen && (
                <Popper
                  ref={ref}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  modifiers={{
                    offset: {
                      enabled: true,
                      offset: '0, 0',
                    },
                  }}
                >
                  <div className={classes.paper}>
                    <ul>
                      <li
                        data-testid="exportIcon"
                        onClick={handleDownloadExcel}
                      >
                        Export Chargebacks as XLSX
                      </li>
                    </ul>
                  </div>
                </Popper>
              )}
                  <LightTooltip title="Filters">
                    <IconButton  aria-label="Filter">
                      <FunnelIcon  />
                    </IconButton>
                  </LightTooltip>
          
                <LightTooltip title="Configure Defaults">
                  <IconButton
                    aria-label="Configure Defaults"
                    style={{ color: 'red' ,marginTop:'-4px'}}
                    onClick={redirectToSetting}
                    >
                    <SettingsIcon />
                  </IconButton>
                </LightTooltip>
          </div>
            
          </Grid>
          {routerValues?.nonTmna?.length > 0 ? (
            <div className={classes.Alerts}>
              <Alert severity="warning">
                <div className={classes.header}>
                  {`${routerValues?.nonTmna?.length} of ${routerValues
                    ?.accountIds?.length +
                    routerValues?.nonTmna
                      ?.length} accounts selected have non-TMNA root value and hence excluded from ${
                    showSpendRate ? 'Spend Rate Graph' : 'Chargebacks'
                  }.`}
                </div>
              </Alert>
            </div>
          ) : (
            ''
          )}
          <form onSubmit={handleSubmit(onSubmitChargeback)}>
            <div style={{ marginLeft: '5px', height: '80px' }}>
              <Grid container spacing={3}>
                <Grid container xs={10} justifyContent="flex-start">
                  <Grid item xs={3} style={{ flexGrow: 2 }}>
                    <h3 style={{ float: 'left', margin: '0px' }}>
                      Account ID(s) <br />
                      {displayAccountIds()}
                    </h3>
                  </Grid>
                  <Grid item xs={3} style={{ flexGrow: 2 }}>
                    <h3 style={{ float: 'left', margin: '0px' }}>
                      Account Names <br />
                      {displayAccountNames()}
                    </h3>
                  </Grid>

                  <Grid item xs={6}>
                    {!showSpendRate && (
                      <>
                        <h3 style={{ margin: '0px' }}>Time Period</h3>

                        <div style={{ marginTop: '10px' }}>
                          <TimeRanges>
                            <Controller
                              control={control}
                              rules={{ required: true }}
                              name="startDate"
                              render={({ field: { value, onChange } }: any) => (
                                <DatePicker
                                  views={['year', 'month']}
                                  //label="From"
                                  helperText="Select start month"
                                  minDate={minFrom2020April}
                                  maxDate={addMonths(new Date(endDate), -1)}
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="endDate"
                              rules={{ required: true }}
                              render={({ field: { value, onChange } }: any) => (
                                <DatePicker
                                  views={['year', 'month']}
                                  //label="To"
                                  helperText="Select end month"
                                  minDate={addMonths(new Date(startDate), 1)}
                                  maxDate={maxToMonth}
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            />
                          </TimeRanges>
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container xs={2}>
                  <Grid item xs={5}>
                    {!showSpendRate && (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ margin: '33px 0 0 8px', padding: '10px 14px' }}
                      >
                        Search
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
          <Grid container style={{ marginTop: '55px', display: 'flex' }}>
            <Grid container xs={12} justifyContent="space-between">
              <Grid item style={{ textAlign: 'right' }}>
                <div>
                  <span>
                    <h3 style={{ margin: '0px' }} data-testid="monthlySpend">
                      Estimated Monthly
                    </h3>
                    <h3 style={{ margin: '0px' }}>
                      Spend
                      <span
                        style={{ color: 'red', marginTop: '0px' }}
                        data-testid="monthlySpend*"
                      >
                        *
                      </span>
                    </h3>
                  </span>
                </div>
                <p style={{ marginTop: '7px', fontSize: '16px' }}>
                  {' '}
                  {resultData?.dataCommon?.monthly_estimated_budget
                    ? `$ ${Number(
                        resultData?.dataCommon?.monthly_estimated_budget,
                      ).toLocaleString('en', options)}`
                    : `$0`}
                </p>
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <div>
                  <h3
                    style={{ margin: '0px', display: 'inline-block' }}
                    data-testid="annualBudget"
                  >
                    Annual Budget
                  </h3>
                  <span
                    style={{
                      color: 'red',
                      margin: '0px',
                      marginLeft: '3px',
                      display: 'inline-block',
                    }}
                    data-testid="annualBudget**"
                  >
                    **
                  </span>
                </div>
                <br />
                <p style={{ marginTop: '7px', fontSize: '16px' }}>
                  {' '}
                  {resultData?.dataCommon?.annual_budget
                    ? `$ ${Number(
                        resultData?.dataCommon?.annual_budget,
                      ).toLocaleString('en', options)}`
                    : `$0`}
                </p>
              </Grid>

              <Grid item style={{ textAlign: 'right' }}>
                <h3 style={{ margin: '0px' }} data-testid="estimatedBudget">
                  Estimated Budget
                </h3>
                <span style={{ color: 'red' }}>{dateShow}</span>
                <p style={{ marginTop: '7px', fontSize: '16px' }}>
                  {' '}
                  {resultData?.dataCommon?.estimated_budget
                    ? `$ ${Number(
                        resultData?.dataCommon?.estimated_budget,
                      ).toLocaleString('en', options)}`
                    : `$0`}
                </p>
              </Grid>

              <Grid item style={{ textAlign: 'right' }}>
                <h3
                  style={{ margin: '0px', marginLeft: '34px' }}
                  data-testid="actualSpent"
                >
                  Actual Spend
                </h3>
                <span style={{ color: 'red' }}>{dateShow}</span>
                <p style={{ marginTop: '7px', fontSize: '16px' }}>
                  {' '}
                  {resultData?.dataCommon?.actual_spent
                    ? `$ ${Number(
                        resultData?.dataCommon?.actual_spent,
                      ).toLocaleString('en', options)}`
                    : `$0`}
                </p>
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <h3
                  style={{ margin: '0px', marginLeft: '34px' }}
                  data-testid="budgetRemain"
                >
                  Budget Remaining
                </h3>
                <span style={{ color: 'red' }}>{dateShow}</span>
                <p
                  style={{ marginTop: '7px', fontSize: '16px' }}
                  className={
                    resultData?.dataCommon?.budget_remaining < 0
                      ? classes.dangerText
                      : ''
                  }
                >
                  {' '}
                  {resultData?.dataCommon?.actual_spent
                    ? `$ ${Math.abs(
                        Number(resultData?.dataCommon?.budget_remaining),
                      ).toLocaleString('en', options)}`
                    : `$0`}
                </p>
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <h3
                  style={{ margin: '0px', marginLeft: '34px' }}
                  data-testid="budgetUtilized"
                >
                  Budget Utilization
                </h3>
                <span style={{ color: 'red' }}>{dateShow}</span>
                <p
                  style={{ marginTop: '7px', fontSize: '16px' }}
                  className={
                    resultData?.dataCommon?.budget_utilization_rate > 100
                      ? classes.dangerText
                      : ''
                  }
                >
                  {' '}
                  {resultData?.dataCommon?.actual_spent
                    ? `${Number(
                        resultData?.dataCommon?.budget_utilization_rate,
                      ).toLocaleString('en', options)}%`
                    : `0%`}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ marginTop: '10px', marginBottom: '-20px' }}>
            <div style={{ display: 'flex' }}>
              <p style={{ color: 'red', marginRight: '5px', margin: '0px' }}>
                *
              </p>
              <p style={{ marginTop: '0px' }}>
                Est. Monthly Spend as maintained in Cloud Account Master (CAM).{' '}
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <p style={{ color: 'red', marginTop: '-12px' }}>**</p>
              <p style={{ marginTop: '-12px' }}>
                Annual Budget is derived for the given year based on this
                Monthly Spend Estimate.{' '}
              </p>
            </div>
          </div>
          {!showSpendRate ? (
            <div
              className={classes.tableContainer}
              data-testid="chargeBackTable"
            >
              <ChargebacksDetailTable
                accountsChargebacks={resultData?.accountsData}
                months={resultData?.months}
                isFetching={loading || defaultLoadDataLoading}
                formScheme="accountID"
              />
            </div>
          ) : (
            renderSpendRate()
          )}
        </Content>
      </Paper>
    </PageLayout>
  );
};
