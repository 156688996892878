import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const customDialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    titleIconBox: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -60%)',
      padding: 10,
      borderRadius: '50%',
      background: 'white',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[900],
    },
  });

export const useStyles = makeStyles<Theme>(theme => {
  return {
    ratingRoot: {
      fontSize: '3rem !important',
    },
    feedbackIconButton: {
      position: 'fixed',
      zIndex: 1,
      right: 10,
      bottom: 10,
      backgroundColor: '#e0e0e0',
    },
    titleIcon: {
      color: 'red',
      fontSize: '4rem',
    },
    dialogContentText: {
      color: theme.palette.grey[900],
    },
    dialogPaper: {
      overflow: 'visible',
    },
    wave: {
      animation: 'none',
      '&.active': {
        animation: '$wave 1.5s ease-in-out 2',
      },
    },
    '@keyframes wave': {
      '0%': {
        transform: 'scale(1)',
        opacity: 0.7,
      },
      '50%': {
        transform: 'scale(1.2)',
        opacity: 1,
        color: 'red',
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 0.7,
      },
    },
  };
});
