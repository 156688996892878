import React, { useEffect, useState, useRef } from 'react';
import ExportIcon from '@material-ui/icons/GetApp';
import { EmptyDataMessage } from 'tmna-ui-kit';
import type { CloudAccountChargebackDetailsPeriod } from 'tmna-types';
import { Table } from '@backstage/core-components';
import Popper from '@material-ui/core/Popper';
import { getColumns } from './columns';
import { getTotalCustom,mapChargebacksToTableData, mapMonthsToDatePeriods } from './utils';
import { useStyles } from './styles';
import * as XLSX from 'xlsx';
import GetAppIcon from '@material-ui/icons/GetApp';
import { MTableBody } from 'material-table';
import { TableFooter, TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';


const DEFAULT_MESSAGE = "No chargebacks to display";
const DEFAULT_FILE_NAME = "Cloud Chargebacks";
const PAGE_SIZE_OPTIONS = [10, 20, 30];

const getTableOptions = (tableData: Array<any>) => ({
  search: false,
  sorting: true,
  draggable: false,
  toolbar: false,
  exportFileName: DEFAULT_FILE_NAME,
  paging: true,
  pageSize: PAGE_SIZE_OPTIONS[0],
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  emptyRowsWhenPaging: false,
  filtering: tableData && tableData.length > 0,
} as const);

interface Props {
  accountsChargebacks?: CloudAccountChargebackDetailsPeriod[];
  months?: string[];
  isFetching: boolean;
  error?: Error;
  showBusinessUnitsColumns?: boolean;
  formScheme: string;
}


export const ChargebacksDetailTable: React.FC<Props> = ({
  accountsChargebacks = [],
  months = [],
  isFetching,
  error = null,
  formScheme,
}) => {
  const classes = useStyles();
  const datePeriods = React.useMemo(() => mapMonthsToDatePeriods(months.filter(e => e !== "total")), [months]);
  const [anchorEl, setAnchorEl] = React.useState(null);  
  const columns = React.useMemo(() => (
    getColumns(datePeriods) //showBusinessUnitsColumns
  ), [datePeriods]); //showBusinessUnitsColumns 

  const mappedTableData = React.useMemo(
    () => mapChargebacksToTableData(accountsChargebacks),
    [accountsChargebacks]
  );
  
  const tableData = React.useMemo(
    () =>
      mappedTableData.sort((a, b) => {
        const accId_A = a?.accountId ?? "";
        const accId_B = b?.accountId ?? "";
        return accId_A.localeCompare(accId_B);
      }),
    [mappedTableData]
  );
  

  const tableOptions = React.useMemo(() => getTableOptions(tableData), [tableData]);

  const handleDownloadExcel=()=>{
    const newData=tableData.map(row=>{
        // delete row.tableData
        return {Account_Id:row.accountId,...row.period}
      })
      const workSheet=XLSX.utils.json_to_sheet(newData)
      const workBook=XLSX.utils.book_new()  
      XLSX.utils.book_append_sheet(workBook,workSheet,"CAM")
      //Buffer
    //   let buf=XLSX.write(workBook,{bookType:"csv",type:"buffer"})
      //Binary string
      XLSX.write(workBook,{bookType:"csv",type:"binary"})
      //Download
      XLSX.writeFile(workBook,"Chargeback_CAM.csv")
  }


  const handleClickExport = (event: any) => {
    setIsMenuOpen(oldState => !oldState)
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const ref = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any; }) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
        setAnchorEl(null);
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen])
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popper' : undefined;
  return (
    <div className={classes.toolbar}>
      { tableData.length > 0 &&
      <Tooltip title="Export">
      <IconButton onClick={handleClickExport} aria-label="export" className={ formScheme === "accountID" ? classes.accountID : ""}>
        <GetAppIcon />
      </IconButton>
      </Tooltip>   
      }  
      <div className={classes.MuiTableSortLabelRoot}>
        <Table
          style={{
            boxShadow: '',
            width: '100%',
            position: 'static',
            backgroundColor: '#F7F7F7',
          }}
          options={tableOptions}
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          localization={
            {
              body: {
                emptyDataSourceMessage: <EmptyDataMessage
                  message={error?.message || DEFAULT_MESSAGE}
                />
              }
            }
          }
          actions={[
            {
              icon: () => <ExportIcon />, tooltip: "Export",
              onClick: (e) => handleClickExport(e),
              isFreeAction: true,
            }
          ]}
          components={{
            Body: (props) => {
              const filteredData = getTotalCustom(months, tableData);
              if (props.renderData === undefined || props.renderData.length === 0) {
                return (
                  <>
                    <MTableBody {...props} />
                    <TableFooter />
                  </>
                )
              }
              return (
                <>
                  <MTableBody {...props} />
                  <TableFooter className={classes.tableData}>
                    <TableRow>
                      {props.columns.filter((hiddenItem: { hidden: any }) => !hiddenItem.hidden).map((item: { field: any }, index: any) =>
                        <TableCell
                          align="right"
                          style={{
                            width: '140px',
                            minWidth: '140px',
                            color: 'inherit',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            whiteSpace:'nowrap'
                          }}
                        >                        
                          {filteredData[item.field ? item.field : ""] ? (filteredData[item.field ? item.field : ""] == "NaN" ? `Pending` : `${filteredData[item.field ? item.field : ""]}`) : index === 0 ? "TOTAL" : ""}
                        </TableCell>

                      )}
                    </TableRow>
                  </TableFooter>
                </>
              )
            }
          }}     
        /> 
      </div> 
      {isMenuOpen && (<Popper ref={ref} id={id} open={open} anchorEl={anchorEl} modifiers={{
        offset: {
          enabled: true,
          offset: '0, 0'
        }
      }}>
        <div className={classes.paper}>
          <ul>
            <li onClick={handleDownloadExcel}>Export as CSV</li>
          </ul>
        </div>
      </Popper>)}
    </div>
  )
};
