import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box, IconButton, TextField, Tooltip } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  alertApiRef,
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CustomDialogTitle } from './CustomDialogTitle';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { userFeedbackApiRef } from '../../api';
import { FeedbackFormData } from './types';
import { CustomRating } from './CustomRating';
import { useStyles } from './styles';

export const MENU_MAPPING: Record<string, string> = {
  welcome: 'Home',
  search: 'Site Search',
  archie: 'Archie',
  catalog: 'App Catalog',
  techradar: 'Tech Radar',
  docs: 'TechDocs',
  'cloud-account': 'Cloud Account',
  create: 'Blueprints',
  'devops-tools-costs': 'Devops Tools Cost',
  insights: 'Insights',
  blogs: 'Blogs',
  training: 'Training',
  notifications: 'Notifications',
  settings: 'User Profile',
};

export const getMenuItemFromUrl = (): string => {
  const url = window.location.href;
  const cleanUrl = url.split('?')[0];
  const firstPath = cleanUrl.split('/')[3];
  return MENU_MAPPING[firstPath] || MENU_MAPPING.welcome;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const UserFeedback = () => {
  const [open, setOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const classes = useStyles();
  const identityApi: IdentityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);
  const userFeedbackAPI = useApi(userFeedbackApiRef);

  const { control, handleSubmit, reset } = useForm<FeedbackFormData>({
    defaultValues: {
      rating: null,
      comments: '',
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data: FeedbackFormData) => {
    try {
      const param = {
        user_email: (await identityApi.getProfileInfo()).email,
        menu: getMenuItemFromUrl(),
        url: window.location.href,
        rating: data.rating,
        comments: data.comments,
      };

      await userFeedbackAPI.saveUserFeedback(param);
      handleClose();

      alertApi.post({
        message: 'Thanks for your feedback!',
        severity: 'success',
        display: 'transient',
      });
    } catch (error) {
      alertApi.post({
        message: 'Something went wrong, Please try again',
        severity: 'error',
        display: 'transient',
      });
    }
  };

  useEffect(() => {
    const triggerAnimation = () => {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 5000);
    };
    const intervalId = setInterval(triggerAnimation, 30 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Fragment>
      <IconButton
        className={clsx(classes.feedbackIconButton, classes.wave, {
          active: animate,
        })}
        onClick={handleClickOpen}
        color="secondary"
      >
        <Tooltip title="Leave a Feedback">
          <FeedbackIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
      >
        <CustomDialogTitle
          onClose={handleClose}
          titleIcon={<FavoriteIcon className={classes.titleIcon} />}
        />
        <DialogContent>
          <Box mb={2}>
            <DialogContentText className={classes.dialogContentText}>
              Please rate this feature
            </DialogContentText>
            <Controller
              name="rating"
              control={control}
              rules={{ required: 'Please provide a rating' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <CustomRating
                  value={value}
                  onRatingChange={newValue => onChange(newValue)}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <Box>
            <DialogContentText className={classes.dialogContentText}>
              Leave us a feedback or suggestion
            </DialogContentText>
            <Controller
              name="comments"
              control={control}
              // rules={{ required: 'Please provide your feedback' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={4}
                  maxRows={4}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
