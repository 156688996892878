import {
  AlertApi,
  IdentityApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';
import axios from 'axios';

type UserFeedbackApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
  alertApi: AlertApi;
};

interface IUserFeedbackApi {
  saveUserFeedback(param: any): Promise<{ response: string }>;
}

export const userFeedbackApiRef = createApiRef<IUserFeedbackApi>({
  id: 'plugin.user-feedback',
});

export class UserFeedbackApi implements IUserFeedbackApi {
  private configApi: Config;
  private identityApi: IdentityApi;

  constructor(options: UserFeedbackApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  public async saveUserFeedback(data: any) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const url = new URL(`${backendUrl}/api/users/insertFeedback`);
    const token = await this.identityApi.getCredentials();

    const response = await axios.post(url.toString(), data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    return response.data;
  }
}
