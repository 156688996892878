import { AccountInfoAuditHistory } from '../../types';
import { Table } from '@backstage/core-components';
import { TableColumn } from '@backstage/core-components';
import React, { FC, ReactElement } from 'react';
import { FilterField } from '../components/FilterField';

/**Declarations */
type auditHistoryProps = {
  rowData: Array<AccountInfoAuditHistory>;
  isLoading:boolean;
};
const PAGE_SIZE_OPTIONS = [10, 20, 30];
const DEFAULT_VALUE = 'N/A';

/**Declaring Columns for Audit history table */
const tableColumns: Array<TableColumn<AccountInfoAuditHistory>> = [
  {
    field: 'modifiedAttributes',
    title: 'MODIFIED ATTRIBUTES',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('0', v)} />
    ),
  },
  {
    field: 'changedFrom',
    title: 'CHANGED FROM',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('1', v)} />
    ),
  },
  {
    field: 'changedTo',
    title: 'CHANGED TO',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('2', v)} />
    ),
  },
  {
    field: 'modifiedBy',
    title: 'MODIFIED BY',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('3', v)} />
    ),
  },
  {
    field: 'date',
    title: 'DATE',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('4', v)} />
    ),
  },
  {
    field: 'comments',
    title: 'COMMENTS',
    emptyValue: DEFAULT_VALUE,
    filterComponent: props => (
      <FilterField onChange={v => props.onFilterChanged('5', v)} />
    ),
  }
];

/**View Audit history table */
const AuditHistory: FC<auditHistoryProps> = ({ rowData,isLoading }): ReactElement => {

  return (
    <Table<AccountInfoAuditHistory>
      options={{
        paging: true,
        padding: 'dense',
        pageSize: PAGE_SIZE_OPTIONS[0],
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        emptyRowsWhenPaging: false,
        filtering: true,
        search: false,
        toolbar: false,
      }}
      isLoading={isLoading}
      columns={tableColumns}
      data={rowData}
    />
  );
};
AuditHistory.displayName = 'Audit History Table';
export default AuditHistory;
