import { catalogApiRef, humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldProps } from '@rjsf/core';
import React from 'react';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';

export const CustomEntityPicker = ({
    onChange,
    schema: { title = 'Entity', description = 'An entity from the catalog' },
    required,
    //uiSchema,
    rawErrors,
    formData,
}: FieldProps<string>) => {
    const catalogApi = useApi(catalogApiRef);

    const { value: entities, loading } = useAsync(() =>
        catalogApi.getEntities({
            filter: { kind: 'Component', 'spec.type': 'application' },
        }),
    );

    const entityRefs = entities?.items.map(e => humanizeEntityRef(e, { defaultKind: 'Component' }));

    const onSelect = (_: any, value: string | null) => {
        onChange(value || '');
    };

    return (
        <FormControl
            margin="normal"
            required={required}
            error={rawErrors?.length > 0 && !formData}
        >
            <Autocomplete
                value={(formData as string) || ''}
                loading={loading}
                onChange={onSelect}
                options={entityRefs || []}
                autoSelect
                freeSolo
                renderInput={params => (
                    <TextField
                        {...params}
                        label={title}
                        margin="normal"
                        helperText={description}
                        variant="outlined"
                        required={required}
                        InputProps={params.InputProps}
                    />
                )}
            />
        </FormControl>
    );
};
