import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CustomDialogTitleProps } from './types';
import { withStyles } from '@material-ui/core/styles';
import { customDialogTitleStyles } from './styles';
import { Typography, IconButton, Box } from '@material-ui/core';

export const CustomDialogTitle = withStyles(customDialogTitleStyles)(
  (props: CustomDialogTitleProps) => {
    const { children, classes, onClose, titleIcon, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Box className={classes.titleIconBox}>{titleIcon}</Box>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  },
);
