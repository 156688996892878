import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';
import {User} from './components/ExampleFetchComponent/ExampleFetchComponent';

type FinopsHubApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

interface IFinopsHubApi {
    health(): Promise<{status: string}>;
    getExampleUsers(): Promise<{results: User[]}>;
}

export const finopsHubApiRef = createApiRef<IFinopsHubApi>({
    id: 'plugin.finops-hub'
})

export class FinopsHubApi implements IFinopsHubApi {
      private configApi: Config;
      private identityApi: IdentityApi;
    
      constructor(options: FinopsHubApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
      }

      async health() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const token = await this.identityApi.getCredentials();
        const requestUrl = `${backendUrl}/api/finops-hub/health`;
        const response = await fetch(requestUrl, {
            method: 'GET',
            credentials: 'include',
            headers: { Authorization: `Bearer ${token.token}` }
        });

        if (!response.ok || !response.body) {
            throw response.statusText;
        }

        return response.json();
      }

      async getExampleUsers() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const token = await this.identityApi.getCredentials();
        const requestUrl = `${backendUrl}/api/finops-hub/example-users`;
        const response = await fetch(requestUrl, {
            method: 'GET',
            credentials: 'include',
            headers: { Authorization: `Bearer ${token.token}` }
        });

        if (!response.ok || !response.body) {
            throw response.statusText;
        }

        return response.json()
      }
}