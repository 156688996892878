import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { FinopsHubApi, finopsHubApiRef } from './api';

export const finopsHubPlugin = createPlugin({
  id: 'finops-hub',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: finopsHubApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, identityApi }) =>
        new FinopsHubApi({ identityApi, configApi }),
    }),
  ],
});

export const FinopsHubPage = finopsHubPlugin.provide(
  createRoutableExtension({
    name: 'FinopsHubPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);
