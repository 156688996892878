import {makeStyles} from '@material-ui/core';
import {TMNATheme} from 'tmna-theme';

export const useStyles = makeStyles<TMNATheme>((theme) => ({
    tableToolbar: {
        // TODO: crutch for export-button positioning. Need to find better solution!
        position: 'absolute',
        top: 0,
        right: 0,

        '& > div': {
            padding: `${theme.spacing(2)}px !important`
        },

        '& svg': {
            color: theme.palette.tmnaColors.secondary.slateGray,
        }
    },
    toolbar:{
        '& > div > div > div:first-child': {
            position: 'absolute',
            right:0,
            top: "0px"
        },
        '& th':{
            '&:last-child':{
                color: 'black'
               }
        }

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius:4,
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        '& ul':{
            listStyle:'none',
            padding: 5,
            margin: 0,
            paddingTop: 8,
            paddingBottom: 8,
            cursor: 'pointer',
            '& li':{
                fontSize: '15px',
                paddingLeft: 16,
                paddingRight: 16,
                fontWeight: 400,
                lineHeight: 1.5,
                paddingTop: 6,
                whiteSpace: 'nowrap',
                paddingBottom: 6,
                '&:hover':{
                    color: '#CC0000',
                    background: '#F7F7F7',
                }
            }
        }
      },
      tableData:{ 
          '& td':{
            '&:first-child':{
                textAlign: 'initial'
               }
          }       
        
      },
      MuiTableSortLabelRoot:{
        '& .MuiTableSortLabel-root':{
          flexDirection: 'revert !important',
          fontWeight: theme.typography.fontWeightBold as any,
          '& .MuiTableSortLabel-icon':{
            opacity: 1
          }         
        },                  
        '& th:nth-last-child(-n+3)':{
          paddingRight: '0px !important'
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
            '&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
                color: theme.palette.tmnaColors.primary.red,
            },
        },
      },
      businessUnit: {
        position:'absolute',
        left:'1238px',
        top:'27px',
      },
      costCenter: {
        position:'absolute',
        left:'1280px',
        top:'27px',
      },
      accountID: {
        position:'absolute',
        // left:'1296px',
        // top:'27px',
        top: '278px',
        left: '1260px',
        color: 'red',
        display:'none'
      },
      accountOwner: {
        position:'absolute',
        left:'959px',
        top:'27px',
      }
}));
