import { ErrorPage } from '@backstage/core-components';
import { BootErrorPageProps } from '@backstage/core-plugin-api';
import React, { ReactNode, useEffect } from 'react';
import { useInRouterContext, MemoryRouter } from 'react-router-dom';

function OptionallyWrapInRouter({ children }: { children: ReactNode }) {
  if (useInRouterContext()) {
    return <>{children}</>;
  }
  return <MemoryRouter>{children}</MemoryRouter>;
}

export const BootErrorPage = (props: BootErrorPageProps) => {
  const { error, step } = props;
  const sessionKey = 'load-chunk-reloaded';
  let message = '';
  if (step === 'load-config') {
    message = `The configuration failed to load, someone should have a look at this error: ${error.message}`;
  } else if (step === 'load-chunk') {
    message = `Lazy loaded chunk failed to load, try to reload the page: ${error.message}`;
  }

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem(sessionKey);
    if (!hasReloaded && step === 'load-chunk') {
      console.error('Chunk loading error detected');
      sessionStorage.setItem(sessionKey, 'true');
      window.location.reload();
    } else if (hasReloaded && step === 'load-chunk') {
      console.log(
        'Already attempted to reload once this session. Not reloading again.',
      );
    }
  }, [step]);

  return (
    <OptionallyWrapInRouter>
      <ErrorPage statusMessage={message} stack={error.stack} />
    </OptionallyWrapInRouter>
  );
};
