/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Button,
  Checkbox,
  Divider,
  makeStyles,
  Theme,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React, {
  useEffect, 
} from 'react';

import { useSearch } from '@backstage/plugin-search-react';

const useStyles = makeStyles<Theme>(theme => ({
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    margin: theme.spacing(2, 0, 0, 0),
  },
  formControl: {
    width: '20%',
    marginBottom: '20px',
    marginLeft: 'auto',
    display: 'flex',
    top: '-192px',
  },
  filterBoxTitle: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  title: {
    margin: theme.spacing(1, 0, 0, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
  },
  checkbox: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  categoriesTitle: {
    padding: theme.spacing(2, 0),
    fontSize: '12px',
  },
  chipsDiv: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2, 0, 0, 0),
      '& > *': {
        background: '#EB0A1E',
        border: '0px',
        '& > *': {
          color: 'white',
        },
        '& svg:hover': {
          color: 'white',
        },
      },
    },
  },
  infoTag: {
    display: 'flex',
    marginTop: '10px',
    fontSize: '14px',
    '& label': {
      fontSize: '14px',
      color: '#9B9C9D',
    },
    '& svg': {
      fontSize: '20px',
      color: '#696262',
    },
  },
  categoryGrid: {
    marginInline: '25px',
  },
  sortOption: {
    minWidth: '200px',
    marginRight: '60px',
  },
  sortIcon: {
    marginInline: '5px',
    color: 'red',
  },
  matDivider: {
    marginTop: '35px',
  },
  listHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
}));

export const SearchResultsFilter = ({ isQueryEmpty }: any) => {
  // Declarations

  const searchContext = useSearch();
  let data = searchContext.types;
  const [selectedCategories, setSelectedCategories] = React.useState([] as any);
  const homeTypes = ['aws-feed', 'gcp-feed', 'news'];

  //Methods
  const formatSelectedTypes = (types: string[]): string[] => {
    const indexHome: number = types.indexOf('home');
    if (indexHome !== -1) {
      types = types.filter(item => item !== 'home');
      types.push(...homeTypes);
    } else {
      types = types.filter(item => !homeTypes.includes(item));
    }
    return types;
  };

  useEffect(() => {
    if (selectedCategories.length === 0) {
      const asHomeTypes: number = data.indexOf('aws-feed');
      if (asHomeTypes !== -1) {
        data.push('home');
      }
      data = data.filter(item => {
        return item !== 'aws-feed' && item !== 'news' && item !== 'gcp-feed';
      });
      setSelectedCategories(data);
    }
  }, [searchContext.types]);

  useEffect(() => {
    if (isQueryEmpty) {
      setSelectedCategories([]);
      setSelectedSorted('');
      searchContext.setTypes([]);
    }
  }, [isQueryEmpty]);

  useEffect(() => {
    var params: any = location.search
      .slice(1)
      .split('&')
      .map(p => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
    if(searchContext.term && searchContext.term !== '' && sessionStorage.getItem('prevSearch') && sessionStorage.getItem('prevSearch') !== params.query){
      setSelectedCategories([]);
      searchContext.setTypes([]);
      setSelectedSorted('');
      sessionStorage.setItem('prevSearch', params.query? params.query : '');
    }else{
      sessionStorage.setItem('prevSearch', params.query? params.query : '');
    }
    if (
      !window.location.search.includes('types') &&
      searchContext.term === ''
    ) {
      setSelectedCategories([]);
    }
  }, [window.location.search]);

  const addCategories = (category: any) => {
    const categories = selectedCategories.includes(category)
      ? selectedCategories.filter(
          (selectedCategory: any) => selectedCategory !== category,
        )
      : [...selectedCategories, category];
    const types = formatSelectedTypes(categories);
    searchContext.setPageCursor('');
    searchContext.setTypes(types);
    setSelectedCategories(categories);
  };

  const classes = useStyles();
  //const [selectedCategories, setSelectedCategories] =  React.useState([] as string[])
  const [selectedSort, setSelectedSorted] = React.useState('');
  // const [checked, setChecked] = React.useState(false)
  // const [matchingEntities, setMatchingEntities] = useState(
  //   [] as any[],
  // );

  const availableCategories = [
    'home',
    'techdocs',
    'blueprints',
    'blogs',
    'training',
    'faq',
  ];
  // const { filters, setFilters } = useSearch();

  // const handleSortChange = (event: any) => {
  //   setSelectedSorted(event.target.value)
  //   if (event.target.value === "AtoZ") {
  //     return setMatchingEntities(
  //       filteredEntities.sort((a: any, b: any) => a.metadata?.title.localeCompare(b.metadata?.title)
  //       ),
  //     );
  //   } else if (event.target.value === "ZtoA") {
  //     return setMatchingEntities(
  //       filteredEntities.sort((a: any, b: any) => -a.metadata?.title.localeCompare(b.metadata?.title)
  //       ),
  //       );
  //   } else if (event.target.value === "Newest First") {
  //     return setMatchingEntities(
  //       filteredEntities.sort((a: any, b: any) => new Date(b.metadata?.date ? b.metadata?.date :new Date() ).valueOf() - new Date(a.metadata?.date ? a.metadata?.date : new Date() ).valueOf()
  //       )
  //       );
  //   } else if (event.target.value === "Oldest First") {
  //     return setMatchingEntities(
  //       filteredEntities.sort((a: any, b: any) => new Date(a.metadata?.date ? a.metadata?.date : new Date()).valueOf() - new Date(b.metadata?.date ? b.metadata?.date : new Date()).valueOf()
  //       )
  //       );

  //   } else if (event.target.value === "Most Popular") {
  //     const templateCountData = filteredEntities.map((t1: any) => ({...t1, ...templateData?.find((t2: any) => t2.blueprintTemplate === t1.metadata?.title) as any}))
  //     return setMatchingEntities(
  //       templateCountData.sort((a: any, b: any) => parseInt(b.count || 0) - parseInt(a.count || 0))
  //       );
  //   }
  // }

  const convertTitleCase = (str: string) => {
    if (str === 'techdocs') {
      return 'TechDocs';
    } else if (str === 'blueprints') {
      return 'Blueprints';
    } else if (str === 'training') {
      return 'Training';
    } else if (str === 'cost-center') {
      return 'Cost Center';
    } else if (str === 'blogs') {
      return 'Blogs';
    } else if (str === 'faq') {
      return 'FAQs';
    } else if (str === 'home') {
      return 'Home';
    }
    return;
  };
  const handleClearFilters = () => {
    setSelectedCategories([]);
    setSelectedSorted('');
    searchContext.setTypes([]);
  };

  // useEffect(()=>{
  //   setSelectCategories(selectedCategories)
  // },[selectedCategories])

  return (
    <>
      <div className={classes.filterBox}>
        <Typography variant="body2" className={classes.filterBoxTitle}>
          Refine Results
        </Typography>{' '}
        <Button
          onClick={() => handleClearFilters()}
          disabled={selectedCategories.length === 0 && selectedSort === ''}
          color="secondary"
        >
          Clear
        </Button>
      </div>
      {/* <Divider /> */}
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            variant="button"
            component="div"
            className={classes.categoriesTitle}
          >
            Categories
          </Typography>

          <List disablePadding dense className={classes.listHorizontal}>
            {availableCategories.map(category => {
              const labelId = `checkbox-list-label-${category}`;
              return (
                <ListItem
                  key={category}
                  dense
                  button
                  onClick={() => addCategories(category)}
                >
                  <Checkbox
                    edge="start"
                    color="primary"
                    checked={selectedCategories.includes(category)}
                    tabIndex={-1}
                    disableRipple
                    className={classes.checkbox}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                  <ListItemText
                    id={labelId}
                    primary={convertTitleCase(category)}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        {/* <Grid item>
          <Typography variant="button" component="div" className={classes.categoriesTitle}>
            Sort
          </Typography>
          <FormControl variant="outlined" className={classes.sortOption}>
              <Select
                value={selectedSort}
                displayEmpty              
                onChange={handleSortChange}   
                IconComponent = {()=><SortIcon fontSize='medium' className={classes.sortIcon}/>}        
              >
                <MenuItem value="">
                Sort
              </MenuItem>
                <MenuItem value="">A to Z</MenuItem> */}
        {/* <MenuItem value="ZtoA">Z to A</MenuItem>
                <MenuItem value="Most Popular">Most Popular</MenuItem>
                <MenuItem value="Newest First">Newest to Oldest</MenuItem>
                <MenuItem value="Oldest First">Oldest to Newest</MenuItem> */}

        {/* </Select>
            </FormControl>
            </Grid>             */}
      </Grid>
      <Divider className={classes.matDivider} />
    </>
  );
};
