import React, { FC } from 'react';
import Rating, {
  IconContainerProps,
  RatingProps,
} from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { CustomRatingProps } from './types';
import { useStyles } from './styles';
import { FormControl, FormHelperText } from '@material-ui/core';

const customIcons: {
  [index: string]: { icon: React.ReactElement; label: string };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon fontSize="inherit" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon fontSize="inherit" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon fontSize="inherit" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon fontSize="inherit" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon fontSize="inherit" />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

export const CustomRating: FC<CustomRatingProps> = ({
  onRatingChange,
  error = false,
  helperText = '',
  ...rest
}) => {
  const classes = useStyles();
  const handleChange: RatingProps['onChange'] = (_event, value) => {
    onRatingChange(value);
  };
  return (
    <FormControl error={error}>
      <Rating
        className={classes.ratingRoot}
        {...rest}
        getLabelText={(value: number) => customIcons[value].label}
        IconContainerComponent={IconContainer}
        max={5}
        onChange={handleChange}
      />
      <FormHelperText children={helperText} />
    </FormControl>
  );
};
