import React, { useEffect } from 'react';

import { TechRadarComponent } from '@backstage-community/plugin-tech-radar';
import { PageLayout,
  SupportButton,
  SupportTextList,
  SupportLinks,
        SupportMultipleLinkCard } from 'tmna-ui-kit';

import {
  Typography,
  // FormControl,
  // MenuItem,
  // Select,
  ListItem,
  List,
  Input,

} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { customTechRadarApiRef } from './api';



const RELATED_DOCS_SUPPORT_LINKS =
[
  {
    to: '/docs/default/Component/ACE-FAQ/Standards/tdp-faq-techradar/',
    title: 'Tech Radar FAQ',
    }
];

const formatDate = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(new Date(date));
};

const RadarMain = () => {
  // const [selectedRadarId, setRadarId] = useState('radardata');
  const [searchText, setSearchText] = React.useState('');
  const [lastUpdatedDate, setLastUpdatedDate] = React.useState<string | null>(
    null,
  );
  const techRadarApi = useApi(customTechRadarApiRef);
  const selectedRadarId = 'radardata';

  const getRepoLastUpdated = async () => {
    const response = await techRadarApi.getRepoLastUpdated();
    const lastUpdated = response ? formatDate(response.lastUpdated) : null;
    setLastUpdatedDate(lastUpdated);
  };

  useEffect(() => {
    getRepoLastUpdated();
  }, []);

  return (
    <PageLayout
      title="Tech Radar"
      subtitle="Enterprise Standards Disposition for Technology Selection by Portfolio"
      headerBackgroundImg="assets/welcome_bg.jpg"
      displayButton={true}
    >
      <div style={{ padding: 15 }}>
            <div style={{ justifyContent: 'space-between', display: 'flex', maxWidth: 1400}}>
          {/* <FormControl variant="outlined">
                    <Select value={selectedRadarId}>
                        <MenuItem value="radardata"> General </MenuItem>
                        <MenuItem value="radardata2"> Manufacturing </MenuItem>
                    </Select>
                
                    <Typography variant="button" style={{ paddingTop: 10}}>
                    LAST PUBLISHED DATE: 02/02/2024
                </Typography>
              
                </FormControl> */}
          {lastUpdatedDate && (
            <Typography variant="body2" style={{ paddingTop: 10 }}>
              <Typography variant="button">Last Updated Date:</Typography>
              {' '}{lastUpdatedDate}
            </Typography>
          )}

          <div style={{ marginLeft: 'auto' }}>
            <SupportButton tooltipTitle="Support" supportIcon="cloud">
              <SupportTextList>
                <Typography variant="body2">
                            Tech Radar is the data discovery of standards that helps teams and leaders understand the current state of 
                            various technologies, assess their potential impact, and make informed decisions 
                            about which technologies to adopt, invest in, or retire. 
                </Typography>
              </SupportTextList>
              <SupportLinks>
                <SupportMultipleLinkCard
               
                  title="Related Documents"
                  items={RELATED_DOCS_SUPPORT_LINKS}
                />
              </SupportLinks>
            </SupportButton>

            <Input
              id="tech-radar-filter"
              type="search"
              placeholder="Filter"
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
        </div>


        <div style={{ position: 'relative', marginTop: '20px' }}>
            <TechRadarComponent searchText={searchText} width={1400} height={700} id={selectedRadarId}/>
            
            

            <div style={{ position: 'absolute', bottom: '-30%', left: 5, border: '2px solid black', padding: '5px' }}>
            <Typography style={{ fontSize: '11px', display: 'inline-block' }}>
              <List style={{ padding: 0 }}>
                <ListItem style={{ display: 'flex', paddingLeft: 40 }}>
                  <strong>LEGEND</strong>
                </ListItem>
                <ListItem style={{ display: 'flex', paddingLeft: 0 }}>
                  <ul>
                                <span style={{ color: '#67b9c5' }}><strong>Standard:</strong></span> Strongly recommended for new implementations.
                  </ul>
                </ListItem>
                <ListItem style={{ display: 'flex', paddingLeft: 0 }}>
                  <ul>
                                <span style={{ color: '#97c270' }}><strong>Emerging:</strong></span> Potential for becoming mainstream, POC / evaluation for enterprise adoption is in-progress.
                  </ul>
                </ListItem>
                <ListItem style={{ display: 'flex', paddingLeft: 0 }}>
                  <ul>
                                <span style={{ color: '#d6cc65' }}><strong>Containment:</strong></span> Not recommended for new applications, existing applications can continue.
                  </ul>
                </ListItem>
                <ListItem style={{ display: 'flex', paddingLeft: 0 }}>
                  <ul>
                                <span style={{ color: '#e8b7b3' }}><strong>Retirement:</strong></span> Not recommended for new and existing applications, no support available.
                  </ul>
                </ListItem>
              </List>
            </Typography>
          </div>
        </div>
      </div>
    </PageLayout>
    )
}
    
        

export default RadarMain;