import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import {Config} from '@backstage/config';
import {NotFoundError} from '@backstage/errors';
import { IRepoLastUpdatedResponse } from 'backstage-plugin-techradar-backend';
import axios from 'axios';

type TechRadarClientOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

interface ITechRadarApi extends TechRadarApi {
  getRepoLastUpdated(): Promise<IRepoLastUpdatedResponse | null>;
}

export const customTechRadarApiRef = createApiRef<ITechRadarApi>({
  id: 'plugin.custom-tech-radar',
});

export class TechRadarClient implements ITechRadarApi {
  private configApi: Config;
  private identityApi: IdentityApi;

  constructor(options: TechRadarClientOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }
  // CDX-8324
  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    // if needed id prop can be used to fetch the correct data
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/techradar/content/${id}`;
        const response = await fetch(
          requestUrl,
          {
      credentials: 'include',
                  headers: { Authorization: `Bearer ${token.token}` }
              },
        );
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return {
      ...data,
          entries: data.entries.map((entry: { timeline: any[]; }) => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }

  async getRepoLastUpdated(): Promise<IRepoLastUpdatedResponse | null> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = await this.identityApi.getCredentials();
    const requestUrl = `${backendUrl}/api/techradar/repoLastUpdated`;

    try {
      const response = await axios.get<IRepoLastUpdatedResponse>(requestUrl, {
        headers: { Authorization: `Bearer ${token.token}` },
      });

      return response.data;
    } catch (error) {
      return null;
    }
  }
}
