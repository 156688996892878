import React from 'react';
import { TMNATheme } from 'tmna-theme';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { LogoutIcon } from './LogoutIcon';
import { SidebarAvatar } from './SidebarAvatar';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { SidebarItem, useSidebarOpenState } from '@backstage/core-components';
import { useAsync } from 'react-use';

const useStyles = makeStyles((theme: TMNATheme) => ({
  linkContainer: {
    position: 'relative',
  },
  logoutIconContainer: {
    height: '100%',
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.navigation.color,
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      color: theme.palette.navigation.selectedColor,
    },
  },
}));

type Props = {
  to: string;
};

// const profile = await identityApi.getProfileInfo();
export const SidebarSettings = ({ to }: Props) => {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const { isOpen } = useSidebarOpenState();
  //     const { value} = useAsync(() =>
  //     identityApi.getProfileInfo();
  //   );
  const { value } = useAsync(() => {
    return identityApi.getProfileInfo();
  });

  const signOut = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      identityApi.signOut();
    },
    [identityApi],
  );

  return (
    <div className={classes.linkContainer}>
      <SidebarItem
        text={value?.displayName}
        to={to}
        icon={() => (
          <SidebarAvatar
            picture={value?.picture}
            displayName={value?.displayName}
          />
        )}
      />
      {isOpen && (
        <IconButton onClick={signOut} className={classes.logoutIconContainer}>
          <LogoutIcon />
        </IconButton>
      )}
    </div>
  );
};
